<template>
  <Transition
    enter-active-class="animate__animated animate__fadeInUp animate__fast"
    leave-active-class="animate__animated animate__fadeOutDown animate__fast"
    appear
  >
    <div
      v-if="showBanner"
      class="z-[9999] fixed bottom-0 left-0 w-screen bg-blue-600 dark:bg-blue-800 text-slate-200 px-2 py-2"
    >
      <div class="flex flex-col gap-2 px-1">
        <p class="tracking-wide md:text-center">
          Our site uses cookies and Google Analytics to analyze website traffic.
          By accepting our use of cookies, you agree to our terms in our
          <NuxtLink to="/privacy-policy"
            ><span class="text-slate-200">privacy policy</span></NuxtLink
          >.
        </p>
        <div class="flex flex-row justify-center gap-4">
          <button
            class="px-2 py-1 rounded-sm text-slate-50 dark:text-slate-100 bg-transparent border border-red-500 dark:border-red-600 tracking-wide"
            @click.prevent="handleReject()"
          >
            Deny
          </button>
          <button
            class="px-2 py-1 rounded-sm text-slate-50 dark:text-slate-100 bg-green-500 dark:bg-green-600 tracking-wide"
            @click.prevent="handleAccept()"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
interface TGSCookie {
  show: boolean;
  accept: boolean;
}
import "animate.css";

const showBanner = ref(false);

const MAX_AGE = 60 * 60 * 24 * 7;

const cookie = useCookie("tgs-allow-ga", {
  maxAge: MAX_AGE,
  expires: new Date(Date.now() + MAX_AGE),
  httpOnly: false,
  secure: true,
  sameSite: "lax",
  default: () => ({ accept: false, show: true }),
});

refreshCookie("tgs-allow-ga");

// console.log("cookie.value", cookie.value);

const handleAccept = async () => {
  const cookieValue: TGSCookie = { accept: true, show: false };
  cookie.value = cookieValue;
  refreshCookie("tgs-allow-ga");
  // console.log(`after accept`, cookie.value);
  showBanner.value = false;
  const { proxy } = useScriptGoogleAnalytics({ id: "G-VS3RF0SECP" });
  // ignore errors
  // @ts-ignore
  proxy.gtag("js", new Date());
  // @ts-ignore
  proxy.gtag("config", "G-VS3RF0SECP");
  return;
};

const handleReject = () => {
  const cookieValue: TGSCookie = { accept: false, show: false };
  cookie.value = cookieValue;
  refreshCookie("tgs-allow-ga");
  showBanner.value = false;
  return;
};

onMounted(async () => {
  await nextTick();
  if (cookie.value.show) {
    setTimeout(() => {
      showBanner.value = true;
    }, 333);
    return;
  }
  if (!cookie.value.accept && !cookie.value.show) {
    return;
  }
  if (cookie.value.accept && !cookie.value.show) {
    // load GA script
    const { proxy } = useScriptGoogleAnalytics({ id: "G-VS3RF0SECP" });
    // ignore errors
    // @ts-ignore
    proxy.gtag("js", new Date());
    // @ts-ignore
    proxy.gtag("config", "G-VS3RF0SECP");
    return;
  }
});
</script>
